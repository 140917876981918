import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { subPath } from "../../../../Constants/Global";
import MoreIcon from "@mui/icons-material/MoreVert";
import { deletePrivateChat, pinRecipientsOrGroupsForUser, unPinChat } from "../../../../Redux/Chatview/action";
import { useAppDispatch, useAppSelector,  } from "../../../../Constants/hooks";
import { muteGroupChat, unMuteGroupChat } from "../../../../Redux/circle/action";

const CircleMenu = ({ userData, groupId,handleUnMute,handleMute }: any) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const [chatId, setChatId] = useState(null);


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleDeletePrivateChat = () => {
    const data = {
      chatId: chatId,
    };
    dispatch(deletePrivateChat(data));
  };
  const handleSetPinChat = () => {
    const data = {
      recipientId: '',
      userId: userData.id,
      groupId: ''
    };
    dispatch(pinRecipientsOrGroupsForUser(data));
  }
  const handleUnPinChat = () => {
    const data = {
      recipientId: '',
      userId: userData.id,
      groupId: groupId
    };
    dispatch(unPinChat(data));
  }

  const handleLeaveChat = () => {
    const data = {
      groupId: groupId,
      userId: userData.id,

    };
  }

  const handleMuteChat = () => {
    const data = {
      groupId: groupId,
      userId: userData.id,
      recipientId: ''
    };
    dispatch(muteGroupChat(data));
  }
  const handleunMuteChat = () => {
    const data = {
      groupId: groupId,
      userId: userData.id,
      recipientId: ''
    };
    dispatch(unMuteGroupChat(data));
  }
  

  
//   const chatListData = useAppSelector((state) => state?.chatListData?.chatList?.chatMembersList);
const groupMute = useAppSelector((state: any) => state.groupChat?.groups?.chatGroupsList
);
  console.log("chatListDatachatListData",groupMute);

  
  for (let index = 0; index < groupMute.length; index++) {
    const chatMember = groupMute[index].mutedGroup
    ;
console.log(chatMember,"chatMemberchatMember");

    if (groupId===groupMute[index].userId) {
      
      var mutted = groupMute[index].mutedChat
    }
  
  }
  console.log("groupId",groupId);

  const chatMember = Array.isArray(groupMute) 
  ? groupMute.find((member) => member?.userId)
  : undefined;

console.log("chatMemberc", chatMember);

const handleMutemessage = () => {
  if (chatMember) {
    handleMute(chatMember.userId , true); 
  }
}
const handleUnMutemessage = () => {
  if (chatMember) {
    handleUnMute(chatMember.userId, false); 
  }
}

  return (
    <div>
      <IconButton
        className="iconbutton_chat"
        sx={{ paddingRight: "0px" }}
        size="large"
        edge="end"
        color="inherit"
        aria-label="user menu"
        onClick={handleOpenUserMenu}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleMuteChat}>
          <ListItemIcon onClick={ mutted === false ? handleMutemessage : handleUnMutemessage}  >
            <img
              src={`..${subPath}/assets/images/images/${mutted ===false ?"chat_notification" :"notification_chat"}.png`}
              alt="Mute Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary={ mutted === false ? "Mute Chat" :" UnMute Chat" }className=" moreitem_fs" />
        </MenuItem>
        <MenuItem onClick={() => handleUnPinChat()}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/pin-angle.png`}
              alt="Unpin Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary="Unpin Chat" className="moreitem_fs" />
        </MenuItem>
        <MenuItem onClick={() => handleLeaveChat()} >
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/Leave_Icon.png`}
              alt="Leave Circle Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary="Leave Circle" className="moreitem_fs" />
        </MenuItem>

        <MenuItem onClick={handleDeletePrivateChat}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/trash.png`}
              alt="Delete Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete Chat"
            className="moreitem_fs"
            style={{ color: "#E7554A" }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
export default CircleMenu
