import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useAppDispatch, useAppSelector } from "../../../../../Constants/hooks";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { ENC_DEC_KEY, subPath } from "../../../../../Constants/Global";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CryptoJS from "crypto-js";

import {
  getRepliesForMessageGroupChat,
  theardshow,
} from "../../../../../Redux/GroupChat/action";
import moment from "moment";
import { MessageBox } from "react-chat-elements";
import {
  deleteChatMessage,
  savePinnedMessage,
  updateChatMessage,
} from "../../../../../Redux/Chatview/action";
import CircleMessageTooltip from "./CircleMessageTooltip";

const CircleTheard: React.FC<any> = (props) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const isTheardOpen = useAppSelector((state: any) => state?.groupChat?.isOpen);
  console.log(isTheardOpen, "isTheardOpen");
  const dispatch = useAppDispatch();
  const handleToggleMediaView = () => {
    dispatch(theardshow(!isTheardOpen));
  };

  const [messageInput, setMessageInput] = useState("");
  const socketRef = useRef<WebSocket | null>(null);
  const [editedContent, setEditedContent] = useState("");
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [isForwardMessageShow, setIsForwardMessageShow] = useState(false);
  const [forwardMsg, setForwardMsg] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [msgchat, setMsgChat]: any = useState({
    privateChatMessages: [],
  });
  const [hoveredMessageReply, setHoveredMessageReply] = useState<null | number>(
    null
  );
  const [connectionState, setConnectionState] = useState<
    "CONNECTING" | "OPEN" | "CLOSED"
  >("CONNECTING");
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };
  function decrypt(value: string | CryptoJS.lib.CipherParams): string {
    if (value && value !== "") {
      try {
        const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

        // Decrypting the value
        const decrypted = CryptoJS.AES.decrypt(value, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // Converting decrypted value to UTF-8 string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Logging decrypted value for debugging

        // Return decrypted string
        return decryptedString;
      } catch (error) {
        console.error("Error during decryption:", error);
        return "";
      }
    }

    return "";
  }
  // useEffect(() => {
  //   if (refreshKey>0) {
  //     const data: any = {
  //       messageId: props.sendMsgId,
  //       groupId: props.groupDetails?.groupId,
  //       pageNo: 1,
  //       rowPerPage: 10,
  //     };
  //     const signal = new AbortController();
  //     dispatch(getRepliesForMessageGroupChat(data, signal));
  //   }
  // }, [isTheardOpen, refreshKey, props.sendMsgId, props.groupDetails?.groupId, dispatch]);
  const initWebSocket = () => {
    if (socketRef.current) return;

    const newSocket = new WebSocket(
      `wss://trove-qa-gateway.citpl.info/chat-module/ws/` +
        props?.userData?.id +
        `/goldfish_arm64`
    );

    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
      setConnectionState("OPEN");
    };

    newSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionState("CLOSED");
      setTimeout(() => {
        initWebSocket();
      }, 5000);
    };

    newSocket.onmessage = (event) => {
      if (event?.data) {
        const receivedMessage = JSON.parse(event.data);
        console.log("Message received: ", receivedMessage);
      }
    };
  };

  useEffect(() => {
    initWebSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [props?.userData?.id]);

  const handleClick = () => {
    if (socketRef.current && connectionState === "OPEN") {
      socketRef.current.send(JSON.stringify({ action: "update" }));
      console.log("Sent update action to WebSocket.");
    }
  };
  //
  const STRINGS = {
    new: "new",
    text: "TEXT",
  };
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };
  function encrypt(value: string) {
    const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);
    const encryptedData = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  }
  const sendMessageToSocket = (messageData) => {
    if (socketRef.current && connectionState === "OPEN") {
      socketRef.current.send(JSON.stringify(messageData));
      console.log(">......message", messageData);
    } else {
      initWebSocket();
      // console.error('WebSocket connection is not open.');
    }
  };
  const sendMessage = () => {
    console.log(".............userData", props.userData);
    console.log(".............message", props.message);

    if (
      socketRef.current &&
      connectionState === "OPEN" &&
      messageInput.trim() !== ""
    ) {
      const messageData: any = {
        action: "reply",
        groupId: props?.groupDetails?.groupId,
        groupName: props?.groupDetails?.groupName,
        groupProfilePicture: "",
        message: JSON.stringify({
          id: generateUniqueId(),
          content: encrypt(messageInput),
          caption: "",
          type: STRINGS.text,
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        }),
        recipientId: "",
        recipientName: "",
        replySenderId: props.sendId,
        replySenderName: props?.dataName,
        replyTo: JSON.stringify({
          id: props?.sendMsgId,
          type: "TEXT",
          content: encrypt(props.message?.message?.content),
          caption: "",
          size: 0,
          name: null,
          seenAt: null,
          sendAt: new Date(),
          updatedAt: new Date(),
          createdAt: new Date(),
        }),
        senderId: props?.userData?.id,
        senderName: props.userData?.fullname,
        senderProfilePicture: "",
        department: "",
        designation: "",
        colorCode: "",
        type: STRINGS.text,
        deviceToken: "",
        status: "sent",
      };

      console.log(">......message", messageData);

      socketRef.current.send(JSON.stringify(messageData));
      setRefreshKey((prevKey) => prevKey + 1);

      const updatedMessages = replyData?.groupThreadMessages?.map((obj: { message: { id: any; }; }) =>
        obj.message.id === props.sendMsgId ? messageData : obj
      );

      setMsgChat((prevMsgChat) => ({
        ...prevMsgChat,
        privateChatMessages: updatedMessages,
      }));

      const data: any = {
        messageId: props.sendMsgId,
        groupId: props.groupDetails?.groupId,
        pageNo: 1,
        rowPerPage: 10,
      };
      const signal = new AbortController();
      dispatch(getRepliesForMessageGroupChat(data, signal));
      setMessageInput("");
    } else {
      initWebSocket();
      // console.error('WebSocket connection is not open or message input is empty.');
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingMessageId]);
  const handleEditClick = (messageId: any, currentContent: any) => {
    setEditingMessageId(messageId);
    setEditedContent(currentContent);
  };
  const handleForwardMessageShow = (message: any) => {
    setIsForwardMessageShow(true);
    setForwardMsg(message);
  };
  const handleDeleteMessage = (message: any) => {
    dispatch(deleteChatMessage(message));
  };
  const handlePinShow = (message: any) => {
    const data = {
      userId: message?.senderId,
      recipientId: message?.recipientId,
      groupId: message?.groupId,
      messageId: message?.id,
      message: message,
    };
    console.log(data, "message");

    dispatch(savePinnedMessage(data));
  };
  const handleContentChange = (event: any) => {
    setEditedContent(event.target.value);
  };
  const updateMessage = async (message: any) => {
    dispatch(updateChatMessage(message));
    const value = replyData?.groupThreadMessages?.map((obj: any) =>
      obj.message.id ? message : obj
    );
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: value,
    }));
    const data: any = {
      messageId: props.sendMsgId,
      groupId: props.groupDetails?.groupId,
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getRepliesForMessageGroupChat(data, signal));
  };

  const sendMessageEnter = (message: any) => {
    console.log(message, "chatDetails");
    const messageData: any = {
      action: "edit",
      groupId: props.groupDetails?.groupId,
      groupName: props.groupDetails?.groupName,
      groupProfilePicture: "",
      replySenderId: "",
      replySenderName: "",
      replyTo: "",
      senderId: message?.senderId,
      senderName: message?.senderName,
      senderProfilePicture: "",
      department: message?.recipientDepartment,
      designation: message?.recipientDesignation,
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
      message: {
        id: message.message.id,
        content: encrypt(editedContent),
        caption: "",
        type: "TEXT",
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
    };
    updateMessage(messageData);
  };

  const handleKeyPress = (event: any, message: any) => {
    handleClick();
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessageEnter(message);
    }
  };
  const replyData = useAppSelector(
    (state: any) => state?.groupChat?.repliesForMessage
  );
  // replyData?.groupThreadMessages,
  useEffect(() => {
    const circled = replyData?.groupThreadMessages?.sort((a: any, b: any) => {
      const dateA = new Date(a.message.createdAt).getTime();
      const dateB = new Date(b.message.createdAt).getTime();

      return dateA - dateB;
    });
    setMsgChat((prevMsgChat) => ({
      privateChatMessages: circled,
    }));
  }, [replyData]);
  console.log("...........dtaMeaaseg", props);
  console.log(".............hoveredMessageReply", hoveredMessageReply);
  return (
    <div>
      <Drawer
        open={isTheardOpen}
        onClose={toggleDrawer(false)}
        anchor="right"
        className="theard_drawer"
      >
        <Grid container>
          <Grid
            item
            xs={2}
            sm={2}
            lg={1.4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={`..${subPath}/assets/images/images/Theard_img.png`}
              alt="Theard_img"
              style={{ width: "55px", height: "55px", borderRadius: "4px" }}
            />
          </Grid>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center" }}
            xs={8}
            sm={8}
            md={8.7}
            lg={9}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "13px",
                fontWeight: 600,
                color: "#44464C",
              }}
            >
              Thread
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.2}
            sm={2}
            md={1.3}
            lg={1.6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={handleToggleMediaView}>
              <img
                src={`..${subPath}/assets/images/images/Cancle_icon.png`}
                alt="cancle"
                style={{ width: "25px", height: "25px" }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ overflow: "auto", height: "100%", paddingBottom: "100px" }}
        >
          <Grid item>
            <Timeline position="alternate" className="timeline_connector">
              <TimelineItem>
                <TimelineSeparator>
                  <Avatar
                    src={props?.sendPicture}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{ paddingRight: "0px", paddingBottom: "40px" }}
                >
                  <Grid container sx={{ paddingTop: "5px" }}>
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {props?.dataName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          color: "#414B54",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {props.senderAt}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    sx={{
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingTop: "5px",
                    }}
                  >
                    {props?.dataContent}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              {msgchat?.privateChatMessages?.map((user: any, index: any) => (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    marginLeft: "19px",
                    paddingBottom: "20px",
                    borderLeft:
                      index < msgchat.privateChatMessages.length - 1
                        ? "2px solid #D4D9DE"
                        : "none",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      borderBottom: "2px solid #d4d9dead",
                      borderRadius: " 0px 0px 0px 50px",
                      height: "20px",
                      marginLeft:
                        index < msgchat.privateChatMessages.length - 1
                          ? "-2px"
                          : "0px",

                      width: "8%",
                      borderLeft: "2px solid #D4D9DE",
                    }}
                  ></Grid>
                  <Grid item xs={2} md={1.8} lg={1.2}>
                    <Avatar
                      src={user?.senderProfilePicture}
                      alt="Recepient_photo"
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid container sx={{ paddingTop: "6px" }}>
                    <Grid
                      item
                      xs={8}
                      md={9}
                      lg={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {user.senderName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={3}
                      lg={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#414B54",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {moment(user?.message?.sendAt).format("hh:mm A")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        onMouseEnter={() => setHoveredMessageReply(index)}
                        onMouseLeave={() => setHoveredMessageReply(null)}
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "2px",
                        }}
                      >
                        <Typography
                          aria-owns={
                            hoveredMessageReply === index
                              ? "mouse-over-popover"
                              : undefined
                          }
                          onMouseEnter={() => setHoveredMessageReply(index)}
                          onMouseLeave={() => setHoveredMessageReply(null)}
                          sx={{
                            color: "#000000",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontWeight: 400,
                            paddingTop: "4px",
                          }}
                        >
                          {editingMessageId === index.toString() ? (
                            <Grid item>
                              <input
                                ref={inputRef}
                                style={{
                                  cursor: "pointer",

                                  border: "none",

                                  width: "100%",

                                  outline: "none",
                                }}
                                type="text"
                                value={editedContent}
                                onKeyPress={(event) =>
                                  handleKeyPress(event, user)
                                }
                                onChange={handleContentChange}
                              />
                            </Grid>
                          ) : (
                            <>
                              {user?.message && decrypt(user?.message?.content)}
                            </>
                          )}
                        </Typography>
                        {hoveredMessageReply === index && (
                          <Grid style={{ marginLeft: "70px" }}>
                            <CircleMessageTooltip
                              groupId={props?.groupDetails?.groupId}
                              msgId={props.message?.message?.id}
                              forwardMessage={() =>
                                handleForwardMessageShow(user)
                              }
                              DeleteMessage={() => handleDeleteMessage(user)}
                              PinClick={() => handlePinShow(user)}
                              ReplyMessage={() => ""}
                              ToggleMediaView={() => {
                                ("");
                              }}
                              MouseEnter={() => setHoveredMessageReply(index)}
                              EditClick={() =>
                                handleEditClick(
                                  index.toString(),
                                  user?.message &&
                                    decrypt(user?.message?.content)
                                )
                              }
                            />
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <TimelineItem className="timeline_item"></TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ position: "fixed", bottom: 0, height: "50px" }}
          className="theard_drawer_footer"
        >
          <Grid
            item
            xs={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`..${subPath}/assets/images/images/Reply_theard.png`}
              alt="Reply_theard"
              style={{ width: "16px", height: "16px" }}
            />
          </Grid>
          <Grid item xs={11.5} sx={{ display: "flex", alignItems: "center" }}>
            <InputBase
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Reply thread"
              sx={{
                width: "30%",
                color: "#708090",
                fontSize: "14px",
                fontStyle: "italic",
                fontWeight: 400,
              }}
            />
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};
export default CircleTheard;
