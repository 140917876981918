import React, { useState, useEffect, useRef, MouseEventHandler } from "react";
import "../ChatMessage/ChatView.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { MessageBox, ReplyMessage } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import { Button, Grid, InputBase, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import CryptoJS from "crypto-js";
import {
  getChatView,
  getPinnedMessage,
  savePinnedMessage,
  deleteChatMessage,
  updateChatMessage,
  deletePinnedMessage,
  muteMessage,
  unMutechat,
} from "../../../../Redux/Chatview/action";

import ChatReply from "../ChatMessage/ChatReply";

import MessageHover from "../ChatMessage/MessageboxTooltip/MessageHover";
import ReplymsgTooltip from "../ChatMessage/MessageboxTooltip/ReplymsgTooltip";
import { getChatList, isMediaShow } from "../../../../Redux/chatList/action";
import {
  getGroupDetails,
  getGroups,
  getMessagesGroupChat,
  getRepliesForMessageGroupChat,
  theardshow,
} from "../../../../Redux/GroupChat/action";

import CircleProfileView from "./CircleProfileView";
import CircleMenu from "./CircleMenu";
import moment from "moment";
import CircleMessageTooltip from "./CreateCircle/CircleMessageTooltip";
import CircleTheard from "./CreateCircle/CircleTheard";
import CircleForwardMessage from "./CircleForwardMessage/CircleForward";
import { log } from "console";
import PreviewAttachment from "../ChatMessage/PreviewAttachment";
import ThumbnailCarousel from "../ChatMessage/ThumbnailCarousel";
import { sendGroupChatMedia, sendMedia } from "../../../../Redux/circle/action";
import AudioPlayer from "react-h5-audio-player";
import H5AudioPlayer from "react-h5-audio-player";
import PinnedMsgModel from "../ChatMessage/MessageboxTooltip/PinnedMsgModel";
interface ChatMessage {
  senderName: string;
  recipientName?: string;
  message: any;
  status: string;
  action: string;
  senderId: string;
  recipientId: string;
  groupId: string;
  groupName: string;
  type: string;
  senderProfilePicture: string;
  replyTo: any;
  replySenderName: string;
  replySenderId: string;
  deviceToken: string;
  groupProfilePicture: string;
  department: string;
  designation: string;
  colorCode: string;
}
const STRINGS = {
  new: "new",
  text: "TEXT",
};
interface DeletePinnedMessage {
  pinnedMessageId: string;
}

const userIdSend: string | null = decodeToken()?.id ?? null;

const CircleView = ({ groupDetails }: any) => {
  console.log("groupDetails.....", groupDetails);
  const pageNo: number | null = 1;
  const rowPerPage: number | null = 40;
  const [chatHover, setChatHover] = useState<null | HTMLElement>(null);
  const inputRefReply = useRef<HTMLInputElement>(null);
  const [editedContentReplay, setEditedContentReplay] = useState("");
  const [empty, setEmpty] = useState<null | boolean>(false);
  const [anchorElPin, setAnchorElPin] = React.useState<null | HTMLElement>(
    null
  );

  const chatPageNumber: any = useRef(1);
  const [forwardMsg, setForwardMsg] = useState({});

  //update seenAt
  const [abortController, setAbortController] = useState(new AbortController());
  const dispatch = useAppDispatch();
  const newAbortController = new AbortController();
  const chatMessage: any = useAppSelector(
    (state: any) => state?.groupChat?.messages
  );

  const pinnedMessage: any = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );
  const [msgchat, setMsgChat] = useState({
    groupChatMessages: [],
    totalNumberOfPages: 0,
  });
  const pinnedChatMessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  for (let index = 0; index < pinnedChatMessage.length; index++) {
    const element = pinnedChatMessage[index];
   
    if (element?.message?.id   === pinnedChatMessage[index]?.message?.id) {
      const pinnedMessagess = pinnedChatMessage[index].message.id;
      
    }
    
  }
  console.log("......pinnedChatMessage",pinnedChatMessage)
  const profileMemberRef = useRef<HTMLDivElement>(null);
  const scrollToProfileMember = () => {
    if (profileMemberRef.current) {
      profileMemberRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const userProfilePicture =
    ".." + subPath + "/assets/images/images/PROFILE_PHOTO.png";
  const pinnedMessageData = {
    userId: userData.id,
    recipientId: "",
    groupId: groupDetails?.groupId,
  };

  const userDetails = {
    senderId: groupDetails?.senderId,
    recipientId: groupDetails?.recipientId,
    pageNo: chatPageNumber.current, // Add this property
    rowPerPage: 10, // Add this property
  };

  useEffect(() => {
    setAbortController(newAbortController);

    // chatDetails.chatDetails
    //   ? dispatch(getCircleView(userDetails, newAbortController))
    //   : "";
    // chatDetails.chatDetails
    //   ? dispatch(getPinnedMessage(pinnedMessageData, newAbortController))
    //   : "";
    setEmpty(groupDetails?.empty);
    initWebSocket();
    changeCircleView();

    // Cleanup function for socket closure
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [dispatch]);

  //Delete Private Chat

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setChatHover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setChatHover(null);
  };

  const open = Boolean(chatHover);
  //
  // const encrypt = (text: string) => {
  //   return CryptoJS.AES.encrypt(text, ENC_DEC_KEY).toString();
  // };
  function encrypt(value: string) {
    const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);
    const encryptedData = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  }
  const fetchChatList = () => {
    const data = {
      userId: userData.id,
      groupName: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getGroups(data, signal.signal));
  };

  function decrypt(value: string | CryptoJS.lib.CipherParams): string {
    if (value && value !== "") {
      try {
        const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

        // Decrypting the value
        const decrypted = CryptoJS.AES.decrypt(value, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // Converting decrypted value to UTF-8 string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Logging decrypted value for debugging

        // Return decrypted string
        return decryptedString;
      } catch (error) {
        console.error("Error during decryption:", error);
        return "";
      }
    }

    return "";
  }
  const [hoveredMessageId, setHoveredMessageId] = useState<null | number>(null);
  const [hoveredMessageReply, setHoveredMessageReply] = useState<null | number>(
    null
  );

  const [isPinShow, setIsPinShow] = useState(
    pinnedChatMessage.length != 0 ? true : false
  );
  const handlePinShow = (message: any) => {
    const isAlreadyPinned = pinnedChatMessage.some(
      (pinnedMsg: any) => pinnedMsg.message.id === message.message.id
    );
  
    if (isAlreadyPinned) {
      console.log("Message is already pinned.");
      return;
    }
    const data = {
      userId: message?.senderId,
      recipientId: message?.recipientId,
      groupId: message?.groupId,
      messageId: message?.message?.id,
      message: message,
    };
    console.log(data, "message");

    dispatch(savePinnedMessage(data))
      .then(() => {
        handlePinnedMsg();
      })
      .catch((error) => {
        setIsPinShow(false);
        console.error("Failed to save pinned message:", error);
      });
  };
  const handlePinnedMsg = () => {
    dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
    setIsPinShow(true);
  };

  // WebSocket
  const socketRef = useRef<WebSocket | null>(null);
  const [connectionState, setConnectionState] = useState<
    "CONNECTING" | "OPEN" | "CLOSED"
  >("CONNECTING");
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [isForwardMessageShow, setIsForwardMessageShow] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [editingMessageReplay, setEditingMessageReplay] = useState(null);
  const initWebSocket = () => {
    const newSocket = new WebSocket(
      `wss://trove-qa-gateway.citpl.info/chat-module/ws/` +
      userData?.id +
      `/goldfish_arm64`
    );
    // const newSocket = new WebSocket(
    //   `wss://trovekafkachat.ckdigital.in/api/ws/66750bb7a852b74175fb4c80/goldfish_arm64`
    // );
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
      setConnectionState("OPEN");
    };

    newSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionState("CLOSED");
      // Reopen WebSocket after a delay (optional)
      // setTimeout(() => {
      // initWebSocket();
      // }, 1000); // Try reconnecting after 5 seconds
    };

    newSocket.onmessage = (event) => {
      if (event?.data?.senderId?.length > 0) {
        const receivedMessage = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      }
    };
  };

  const sendMessage = () => {
    console.log(groupDetails, "groupDetails");
    if (
      socketRef.current &&
      connectionState === "OPEN" &&
      messageInput.trim() !== ""
    ) {
      const messageData: ChatMessage = {
        action: STRINGS.new,
        groupId: groupDetails?.groupId,
        groupName: groupDetails?.groupName,
        groupProfilePicture: "",
        message: JSON.stringify({
          id: generateUniqueId(),
          content: encrypt(messageInput),
          caption: "",
          type: STRINGS.text,
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        }),
        recipientId: "",
        recipientName: "",
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: userData?.id,
        senderName: userData?.fullname,
        senderProfilePicture: "",
        department: "",
        designation: "",
        colorCode: "",
        type: STRINGS.text,
        deviceToken: "",
        status: "sent",
      };
      console.log(messageData, userData, groupDetails, "messageData");
      socketRef.current.send(JSON.stringify(messageData));

      fetchChatList();
      handleFetchPrevChat(true);
      clearInput();
    } else {
      initWebSocket();
      // console.error('WebSocket connection is not open or message input is empty.');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const clearInput = () => {
    setMessageInput("");
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const changeCircleView = () => {
    if (empty == true) {
      setMessages([]);
      clearInput();
    }
  };
  const handleProfileClick = () => {
    setIsProfileShow(!isProfileShow);
  };

  const handleForwardMessageShow = (message: any) => {
    setIsForwardMessageShow(true);
    setForwardMsg(message);
  };
  const handleCloseForwardMessage = () => {
    setIsForwardMessageShow(false);
  };
  // pin

  const handleClickPin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPin(event.currentTarget);
  };

  const handleClosePin = () => {
    setAnchorElPin(null);
  };

  const handleDeleteMessage = (message: any) => {
    dispatch(deleteChatMessage(message))
      .then(() => {
        const newArray = msgchat.groupChatMessages.filter(
          (obj) => obj !== message
        );
        setMsgChat((prevMsgChat: any) => ({
          ...prevMsgChat,
          groupChatMessages: newArray,
        }));
        handleFetchPrevChat(true);
      })
      .catch((error) => {
        console.error("Failed to delete Message:", error);
      });
  };
  const updateMessage = async (message: any) => {
    dispatch(updateChatMessage(message));
    const value = msgchat.groupChatMessages.map((obj: any) =>
      obj.message.id === message.message.id ? message : obj
    );
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      groupChatMessages: value,
    }));
    setTimeout(() => {
      fetchChatList();
      handleFetchPrevChat(true);
    });
  };
  //pinned
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const openModel = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (pinnedMessage) {
      handleClickPinned(pinnedMessage); 
    }


  }
  const handleClickPinned = (pinnedMsg: any) => {
    setHighlightedMessageId(pinnedMsg.message.id);
    setTimeout(() => {
      setHighlightedMessageId(null);
    }, 3000);
  };
  const unPinnedMessage = (id: any) => {
    const data: DeletePinnedMessage = {
      pinnedMessageId: id,
    };
    dispatch(deletePinnedMessage(data))
      .then(() => {
        dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
      })
      .catch((error) => {
        setIsPinShow(true);
        console.error("Failed to un pinned message:", error);
      });
  };

  // edit

  const [editingMessageId, setEditingMessageId] = useState(null);
  // const inputRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingMessageId]);

  const sendMessageEnter = (message: any) => {
    console.log(message, "chatDetails");
    const messageData: ChatMessage = {
      action: "edit",
      groupId: groupDetails?.groupId,
      groupName: groupDetails?.groupName,
      groupProfilePicture: "",
      recipientId: groupDetails?.recipientId,
      recipientName: groupDetails?.recipientName,
      replySenderId: "",
      replySenderName: "",
      replyTo: "",
      senderId: groupDetails?.senderId,
      senderName: groupDetails?.senderName,
      senderProfilePicture: "",
      department: groupDetails?.recipientDepartment,
      designation: groupDetails?.recipientDesignation,
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
      message: {
        id: message.message.id,
        content: encrypt(editedContent),
        caption: "",
        type: "TEXT",
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
    };
    updateMessage(messageData);
  };

  const handleEditClick = (messageId: any, currentContent: any) => {
    setEditingMessageId(messageId);
    setEditedContent(currentContent);
  };

  const handleContentChange = (event: any) => {
    setEditedContent(event.target.value);
  };

  const handleSaveClick = (messageId: any) => {
    // Save the edited content here (e.g., update the message in your state or send it to a server)
    setEditingMessageId(null);
  };

  const handleKeyPress = (event: any, message: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick(editingMessageId);
      sendMessageEnter(message);
    }
  };
  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (inputRefReply.current) {
      inputRefReply.current.focus();
    }
  }, [editingMessageId]);
  const sendMessageEnterReply = (message) => {
    console.log(`Send Message: ${editedContentReplay}`);
    console.log("message:::::::::::::::::", message);
    console.log("chatDetails?.chatDetails?:::::::::::::::::", groupDetails);
    const messageData: ChatMessage = {
      action: "edit",
      groupId: groupDetails?.groupId,
      groupName: groupDetails?.groupName,
      groupProfilePicture: groupDetails?.groupProfilePicture,
      message: {
        id: message.message.id,
        content: encrypt(editedContentReplay),
        caption: "",
        type: STRINGS.text,
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
      recipientId: message?.recipientId,
      recipientName: message?.recipientName,
      replySenderId: message?.senderId,
      replySenderName: message?.senderName,
      replyTo: {
        id: message.replyTo.id,
        type: "TEXT",
        content: encrypt(message?.message?.content),
        caption: "",
        size: 0,
        name: null,
        seenAt: null,
        sendAt: new Date(),
        updatedAt: new Date(),
        createdAt: new Date(),
      },
      senderId: message?.senderId,
      senderName: message?.senderName,
      senderProfilePicture: message?.replySenderProfilePicture,
      department: "",
      designation: "",
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
    };
    console.log(messageData);
    updateMessage(messageData);
  };
  const handleKeyPressReply = (event: any, message: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick(editingMessageReplay);
      sendMessageEnterReply(message);
    }
  };
  const handleContentChangeReply = (event: any) => {
    setEditedContentReplay(event.target.value);
  };
  // console.log("...................handleReplyClick");
  // useEffect(()=>{
  //   const data:any = {
  //     messageId: "1719207123696-477913",
  //     groupId: "6679048e20cfa11d937714be",
  //     pageNo: 1,
  //     rowPerPage: 10,
  //   };
  //   const signal = new AbortController();
  //   dispatch(getRepliesForMessageGroupChat(data, signal));
  // },[dispatch])
  const replyData = useAppSelector(
    (state: any) => state?.groupChat?.repliesForMessage
  );

  console.log("...................data", replyData);
  console.log("...................handleReplyClick");
  const [isEditing, setIsEditing] = useState(false);
  const handleEditStart = () => {
    setIsEditing(true);
    // setEditedContent(decrypt(JSON.parse(message.message).content));
  };
  const handleEditClickReply = (messageId: any, currentContent: any) => {
    console.log(".....click", currentContent);

    setIsEditing(true);
    setEditingMessageId(messageId);
    setEditingMessageReplay(messageId);
    setEditedContentReplay(currentContent);
  };

  //reply
  const [isReplying, setIsReplying] = useState(false);
  const [replyingIndex, setReplyingIndex] = useState(null);
  const [dataName, setDataName] = useState();
  const [dataContent, setDataContent] = useState();
  const [sendId, setSendId] = useState();
  const [sendMsgId, setSendMsgId] = useState();
  const [sendPicture, setSendPicture] = useState();
  const [senderAt, setSenderAt] = useState();
  const [replyId, setReplyId] = useState();
  const isOpen = useAppSelector((state: any) => state?.groupChat?.isOpen);
  const handleToggleMediaView = (
    sendPicture: any,
    msgId: any,
    groupId: any,
    senderName: any,
    dataContents: any,
    sendId: any,
    senderAt: any
  ) => {
    setSendPicture(sendPicture);
    setDataName(senderName);
    setSendMsgId(msgId);
    setDataContent(dataContents);
    setSenderAt(senderAt);
    setSendId(sendId);
    dispatch(theardshow(!isOpen));
    console.log("...................handleReplyClick");
    const data: any = {
      messageId: msgId,
      groupId: groupId,
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getRepliesForMessageGroupChat(data, signal));
  };
  const handleReplyClick = (index: any, id: any) => {
    setReplyId(id);
    setIsReplying(!isReplying); // Toggle reply box visibility
    setReplyingIndex(index);
    const profilePicture = groupDetails?.recipientProfilePicture
      ? groupDetails?.recipientProfilePicture
      : `..${subPath}/assets/images/images/PROFILE_PHOTO.png`;

    const generateMessageData = () => {
      const messageData = {
        action: "new",
        groupId: groupDetails?.groupId,
        groupName: groupDetails?.groupName,
        groupProfilePicture: "",
        message: {
          id: generateUniqueId(),
          content: encrypt(editedContent),
          caption: "",
          type: "TEXT",
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        },
        recipientId: groupDetails?.recipientId,
        recipientName: groupDetails?.recipientName,
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: groupDetails?.senderId,
        senderName: groupDetails?.senderName,
        senderProfilePicture: profilePicture, // Update this field with the profile picture
        department: "",
        designation: "",
        colorCode: "",
        type: "TEXT",
        deviceToken: "",
        status: "sent",
      };
      useEffect(() => {
        // Focus the input element when it is in the editing state
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, [ReplyMessage]);

      const sendMessageEnter = () => {
        console.log(`Send Message: ${ReplyMessage}`);
        const messageData = {
          action: "new",
          groupId: groupDetails?.groupId,
          groupName: groupDetails?.groupName,
          groupProfilePicture: "",
          message: {
            id: generateUniqueId(),
            content: encrypt(editedContent),
            caption: "",
            type: "TEXT",
            sendAt: new Date(),
            updatedAt: new Date(),
            deliveredAt: "",
            seenAt: "",
          },
          recipientId: groupDetails?.recipientId,
          recipientName: groupDetails?.recipientName,
          replySenderId: "",
          replySenderName: "",
          replyTo: "",
          senderId: groupDetails?.senderId,
          senderName: groupDetails?.senderName,
          senderProfilePicture: "",
          department: "",
          designation: "",
          colorCode: "",
          type: "TEXT",
          deviceToken: "",
          status: "sent",
        };
        console.log(messageData);
        updateMessage(messageData);
      };

      const handleEditClick = (messageId: any, currentContent: any) => {
        setEditingMessageId(messageId);
        setEditedContent(currentContent);
      };

      const handleContentChange = (event: any) => {
        setEditedContent(event.target.value);
      };

      const handleSaveClick = (messageId: any) => {
        console.log(`Message ID: ${messageId}, New Content: ${editedContent}`);
        // Save the edited content here (e.g., update the message in your state or send it to a server)
        setEditingMessageId(null);
      };

      const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSaveClick(editingMessageId);
          sendMessageEnter();
        }
      };

      const [isEditing, setIsEditing] = useState(false);
      const handleEditStart = () => {
        setIsEditing(true);
        // setEditedContent(decrypt(JSON.parse(message.message).content));
      };

      console.log(messageData);
      updateMessage(messageData);
    };

    // Call the function to generate and handle the new message data
    generateMessageData();

    // Call the function to generate and handle the new message data
    generateMessageData();
  };
  //scroll
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.groupChatMessages, messages]);
  useEffect(() => {
    const ChatData: any = [
      // ...(msgchat?.groupChatMessages || []),
      ...(chatMessage?.groupChatMessages || []),
    ];
    ChatData.sort((a: any, b: any) => {
      const dateA = new Date(a.message.updatedAt).getTime();
      const dateB = new Date(b.message.updatedAt).getTime();

      return dateA - dateB;
    });
    const uniqueMessages = ChatData.filter(
      (message: any, index: any, self: any) =>
        index === self.findIndex((m: any) => m.id === message.id)
    );

    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      groupChatMessages: uniqueMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessage, messages]);

  const closeButtonStyle = {
    fontSize: "24px",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "black",
    position: "relative",
    left: "",
     display: "flex",
      justifyContent: "end"
  };
  const sendButtonStyle = {
    // Define your send button styles here
  };
  useEffect(() => {
    //handlePinnedMsg()
    setMsgChat(() => ({
      groupChatMessages: chatMessage.groupChatMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));
    console.log(groupDetails, "groupDetailsgroupDetails");
    const CircleDetails = {
      groupId: groupDetails?.groupId,
      userId: userData.id,
      pageNo: pageNo,
      rowPerPage: rowPerPage,
    };
    const signal = new AbortController();
    dispatch(getGroupDetails(CircleDetails, signal));
  }, [groupDetails]);

  const [isScrolledToTop, setIsScrolledToTop] = useState(false);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const scrollTop = chatContainerRef.current.scrollTop;
        const scrollHeight = chatContainerRef.current.scrollHeight;
        const clientHeight = chatContainerRef.current.clientHeight;

        if (
          scrollTop === 0 &&
          msgchat?.totalNumberOfPages !== chatPageNumber?.current
        ) {
          handleFetchPrevChat(true);
          setIsScrolledToTop(true);
        } else {
          setIsScrolledToTop(false);
        }
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [msgchat?.totalNumberOfPages, chatPageNumber?.current]);

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.groupChatMessages, messages]);
  const handleFetchPrevChat = (updateCall: any) => {
    chatPageNumber.current = updateCall ? 1 : chatPageNumber.current + 1;
    dispatch(
      getMessagesGroupChat(
        {
          groupId: groupDetails?.groupId,
          userId: userData?.id,
          pageNo: chatPageNumber?.current,
          rowPerPage: updateCall ? msgchat?.groupChatMessages?.length : 10,
        },
        newAbortController
      )
    );
  };
  console.log(".............hoveredMessageReply", hoveredMessageReply);

  const forwardMeassgeDetails = {
    senderId: userData.id,
    senderName: "",
  };

  const isTheardOpen = useAppSelector((state: any) => state?.groupChat?.isOpen);
  //attachments

  const isMediaView = useAppSelector(
    (state: any) => state?.chatListData?.isMediaView
  );
  const fileType = useAppSelector(
    (state: any) => state?.chatListData?.fileType
  );
  console.log("isMediaView--------->", isMediaView);
  const handleToggleviewgroupchat = (imageUrl, fileType) => {
    console.log("imageUrl, fileType----->", imageUrl, fileType);

    setSelectedImage(imageUrl);
    dispatch(
      isMediaShow({
        isMediaView: !isMediaView,
        imageURL: imageUrl,
        fileType: fileType,
      })
    );
  };
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [files, setFiles] = useState([]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Triggering input file selection
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: any = Array.from(event.target.files).map((file, index) => ({
        file,
        id: `file_${index}`,
        previewUrl: URL.createObjectURL(file),
      }));
      setFiles(files);

      setImagePreview(true);
      if (files.length > 0) {
        setSelectedImage(files[0]);
      }
    }
  };
  const handleThumbnailClick = (image: File) => {
    setSelectedImage(image);
  };
  const onImageSelected = () => {
    const formData = new FormData();
    console.log(
      "groupDetails?.groupDetails?.senderId ....",
      groupDetails.lastMessage.senderId
    );
    formData.append("senderId", groupDetails.lastMessage.senderId || "");
    formData.append("senderName", groupDetails.lastMessage.senderName || "");
    formData.append("groupId", groupDetails.groupId || "");
    formData.append("groupName", groupDetails.groupName || "");
    formData.append(
      "groupProfilePicture",
      groupDetails?.groupProfilePicture || ""
    );
    console.log("form.***********", formData);

    formData.append("department", "");
    formData.append("designation", "");
    formData.append("recipientId", "");
    formData.append("recipientName", "");
    formData.append("senderProfilePicture", "");
    formData.append("colorCode", "");
    formData.append("deviceToken", "");
    formData.append("replyTo", "");
    console.log("form.................................", formData);
    files.forEach((fileObj: { file: File }, index) => {
      const file = fileObj.file;
      if (file.type === "image/png") {
        formData.append(`images[${index}].media`, file);

        formData.append(`images[${index}].id`, generateUniqueId());

        formData.append(`images[${index}].caption`, "");
      } else if (file.type === "application/pdf") {
        formData.append(`documents[${index}].media`, file);

        formData.append(`documents[${index}].id`, generateUniqueId());

        formData.append(`documents[${index}].caption`, "");
      } else if (file.type.startsWith("video/")) {
        formData.append(`videos[${index}].media`, file);

        formData.append(`videos[${index}].id`, generateUniqueId());

        formData.append(`videos[${index}].caption`, "");
      } else if (file.type.startsWith("audio/")) {
        formData.append(`audios[${index}].media`, file);

        formData.append(`audios[${index}].id`, generateUniqueId());

        formData.append(`audios[${index}].caption`, "");
      }
      // Add similar blocks for videos and audios if needed
    });

    console.log("formData........*******", formData);
    dispatch(sendGroupChatMedia(formData))
      .then((res) => {
        const value: any = msgchat.groupChatMessages;

        res.data.data.forEach((imageData: any) => {
          value.push(imageData);
        });

        setMsgChat((prevMsgChat) => ({
          ...prevMsgChat,
          privateChatMessages: value,
        }));
        setTimeout(() => {
          fetchChatList();
          handleFetchPrevChat(true);
        });
        setImagePreview(false);
      })
      .catch((error: any) => {
        console.error("Error:", error);
      });
  };

  const handleViewFile = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCloseView = () => {
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  console.log("imagePreview---->", imagePreview);

  // audio
  const [audioview, setAudioview] = useState(false);
  const [audioOpen, setAudioOpen] = useState(false);

  const handleAudioview = (id) => {
    setAudioview(id);
    setAudioOpen(!audioOpen);
  };
  // audio
  const [showMentionList, setShowMentionList] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [cursorPosition, setCursorPosition] = useState<any>(null);
  const Grouplist: any = useAppSelector(
    (state: any) => state?.groupChat?.groupDetails || { members: [] }
  );
  const arr:any = [];
  for(let i=0;i<Grouplist?.members?.length;i++){  
      arr.push(Grouplist?.members[i].fullname);
  }
  const handleChageInput = (e:any) =>{
    const inputValue = e.target.value;
      setMessageInput(inputValue);
  
      // Find the last occurrence of '@'
      const atSymbolIndex = inputValue.lastIndexOf("@");
  
      // If '@' is typed, show the mention list
      if (atSymbolIndex !== -1 && inputValue[atSymbolIndex + 1] !== " ") {
        const query = inputValue.slice(atSymbolIndex + 1).toLowerCase();
        const filtered = arr.filter((member) =>
          member?.toLowerCase()?.includes(query)
        );
        setFilteredMembers(filtered);
        setShowMentionList(true);
        setCursorPosition(atSymbolIndex + 1);
      } else {
        setShowMentionList(false);
      }
  }
  const handleMentionClick = (member) => {
    // Insert the selected member into the input
    const beforeAt = messageInput.slice(0, cursorPosition - 1);
    const afterAt = messageInput.slice(cursorPosition + member.length);
    const newMessageInput = `${beforeAt}@${member} ${afterAt}`;
  
    setMessageInput(newMessageInput);
    setShowMentionList(false);
  };
  const CustomForwardButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/Refresh_Right.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "40px",
          height: "40px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomRewindButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/Refresh_left.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "40px",
          height: "40px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomPlayButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/play_icon1.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "30px",
          height: "30px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomPauseButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/pause_icon.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "20px",
          height: "20px",
          backgroundColor: "transparent",
        }}
      />
    );
  };

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef<H5AudioPlayer>(null);

  const handleListen = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const handleSeek = (time) => {
    if (playerRef.current && 'currentTime' in playerRef.current) {
      playerRef.current.currentTime = time;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };


  // mute and unmute

  const handleMute = async () => {
    const muteDataa = {
      userId: groupDetails?.userId,
      recipientId: "", 
      groupId: groupDetails?.groupId, 
    };
console.log(muteDataa, "muteDataa");

    dispatch(muteMessage(muteDataa))

   
  };
  const handleUnMute = async () => {
    const unmuteData = {
      userId: groupDetails?.senderId,
      recipientId: "", 
      groupId:groupDetails?.groupId, 
    };
    dispatch(unMutechat(unmuteData))
  }

  return (
    <Grid item>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          // position: "fixed",
        }}
      >
        {imagePreview ? (
          <>
            <div>
              <Grid
                item
                onClick={() => setImagePreview(false)}
                sx={closeButtonStyle}
                mr={4}
                // sx={{ display: "flex", justifyContent: "end" }}
              >
                <img
                  src={`..${subPath}/assets/images/images/Close_Icon.png`}
                  alt="close"
                  style={{ width: "17px", height: "17px" }}
                />
              </Grid>
            </div>
            {selectedImage && (
              <PreviewAttachment
                image={selectedImage}
                onImageSelected={onImageSelected}
              />
            )}
            <ThumbnailCarousel
              images={files}
              onThumbnailClick={handleThumbnailClick}
            />
            <Grid item mr={5} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                onClick={onImageSelected}
                style={sendButtonStyle}
                sx={{
                  background:
                    "linear-gradient(90.31deg, #4CCE1F -65.38%, #119BFF 100%)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "18px",
                  border: "1px solid #4CCE1F",
                }}
              >
                Send
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              sx={{
                height: "60px",
                position: "sticky",
                top: " 60px",
                zIndex: 1,
              }}
            >
              <AppBar
                position="static"
                sx={{
                  backgroundColor: "#ffff",
                  boxShadow: "none",
                  color: "#000000",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <Toolbar className="toolbar_chat">
                  <Grid container>
                    <Grid
                      item
                      xs={1.5}
                      md={0.8}
                      lg={0.6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={handleProfileClick}
                        className="profile_icon"
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <Avatar
                          alt="Profile"
                          style={{
                            borderRadius: "25%",
                            width: "32px",
                            height: "32px",
                          }}
                          src={groupDetails?.groupProfilePicture || undefined}
                        >
                          {!groupDetails?.groupProfilePicture &&
                            groupDetails?.groupName.charAt(0).toUpperCase()}
                        </Avatar>
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={9.3}
                      md={10.6}
                      lg={10.9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          flexGrow: 1,
                          fontSize: "16px",
                          color: "#000000",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {groupDetails?.groupName.charAt(0).toUpperCase() +
                          groupDetails?.groupName?.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      md={0.5}
                      lg={0.5}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <CircleMenu
                        handleUnMute = {handleUnMute}
                        handleMute = {handleMute}
                        groupId={groupDetails.groupId}
                        userData={userData}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Grid>
            {isPinShow && pinnedChatMessage[0]?.groupId === groupDetails?.groupId && pinnedChatMessage?.length > 0 && (
              <>
                <Grid
                  container
                  sx={{
                    position: "sticky",
                    width: "100%",
                    top: "125px",
                    height: "40px",
                    backgroundColor: "#FFFFFF",
                    padding: "4px, 8px, 4px, 8px",
                    borderRadius: "0px, 0px, 1px, 0px",
                    borderBottom: "1px solid #E5E5E5",
                    boxShadow: "0px 0px 2px 0px #0000001F",

                    zIndex: 1,
                  }}
                >
                  <Grid
                    item
                    sm={0.6}
                    sx={{
                      display: "flex",
                      paddingLeft: "8px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={`..${subPath}/assets/images/images/Pin_img_chat.png`}
                      alt="pin chat"
                      width="17"
                      height="17"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={11.4}
                    lg={11}
                    // onDoubleClick={handleClick}
                    onClick={handleClick}
                    // onClick={() => handleClickPinned(pinnedChatMessage[0])}
                    aria-describedby={id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      color: "#000000",
                      fontWeight: "400",
                      cursor: "pointer",
                    }}
                  >
                    {/* {pinnedChatMessage && pinnedChatMessage?.map((pinnedMsg: any) => (
                <p onClick={() => handlePinnedMsg()} key={pinnedMsg}>{decrypt(pinnedMsg[0]?.message?.content)?.substring(0, 10)} ,</p>
              ))} */}
                  {pinnedChatMessage.map((pinnedMsg, index) => {
        const messageContent = decrypt(pinnedMsg?.message?.content);
        const preview = messageContent?.substring(0, 10);

        return (
          <Tooltip key={index} title={messageContent} arrow>
            <span 
              onClick={() => handleClickPinned(pinnedMsg)} 
              style={{ marginRight: "5px", cursor: "pointer" }}
            >
              <Grid item>{preview},</Grid>
            </span>
          </Tooltip>
        );
      })}
        ...
                  </Grid>
                </Grid>
                <Grid item>
                  <PinnedMsgModel
                    unPinnedMessage={() =>
                      unPinnedMessage(pinnedChatMessage[0]?.id)
                    }
                    handleClickPinned={() =>
                      handleClickPinned(pinnedChatMessage[0])
                    }
                    id={pinnedChatMessage[0]?.id}
                    open={openModel}
                    anchorEl={anchorEl}
                  />
                </Grid>
              </>
            )}

            <Box
              sx={{
                // height: "100vh",
                // height: "calc(100vh - 120px)",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                padding: 0,
                // marginBottom: "20px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={8.5}
                  lg={8.5}
                  sx={{
                    gap: "10px",
                    height: "73vh",
                    overflow: "auto",
                    paddingTop: "25px",
                    paddingBottom: "13px",
                  }}
                  ref={chatContainerRef}
                >
                  {/* user get message  */}
                  <Grid container>
                    {msgchat?.groupChatMessages?.map(
                      (message: any, index: number) => {
                        const play: MouseEventHandler<HTMLButtonElement> = (event) => {
                          throw new Error("Function not implemented.");
                        }

                        return (
                          <>
                            <Box
                              className={
                                isProfileShow || isForwardMessageShow === true
                                  ? "Profile_show_cht"
                                  : "Profile_hide_cht"
                              }
                              sx={{
                                padding: 0,
                                margin: "auto",
                                marginRight: "0px",
                                paddingTop: "10px",
                              }}
                            >
                              <Grid
                                item
                                key={index}
                                className={
                                  isProfileShow || isForwardMessageShow === true
                                    ? "Profile_show_cht"
                                    : "Profile_hide_cht"
                                }
                                sx={{
                                  padding: 0,

                                  marginRight: "0px",

                                  // paddingTop: "10px",

                                  display: "flex",
                                }}
                              >
                                <Avatar
                                  alt="Profile"
                                  sx={{
                                    borderRadius: "25%",
                                    width: "35px",

                                    height: "35px",
                                  }}
                                  src={message.senderProfilePicture}
                                />
                                <Box
                                  onMouseEnter={() =>
                                    setHoveredMessageReply(index)
                                  }
                                  onMouseLeave={() =>
                                    setHoveredMessageReply(null)
                                  }
                                  sx={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    onMouseEnter={() =>
                                      setHoveredMessageReply(index)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredMessageReply(null)
                                    }
                                    aria-owns={
                                      hoveredMessageReply === index
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    className="hide_Time"
                                  >
                                    <MessageBox
                                      id={index.toString()}
                                      className={`chatbox_pad ${highlightedMessageId === message?.message?.id ? "ChatPin_highlight chatbox_message_cicle" : ""} ${message?.forwardMessage ? "chatbox_message_cicle" : ""}`}

                                      // className="chatbox_pad chatbox_message_cicle"
                                      position={"left"}
                                      type={"text"}
                                      text={
                                        editingMessageId ===
                                        index.toString() ? (
                                          <Grid item>
                                            <input
                                              ref={inputRef}
                                              style={{
                                                cursor: "pointer",

                                                border: "none",

                                                width: "100%",

                                                outline: "none",
                                              }}
                                              type="text"
                                              value={editedContent}
                                              onKeyPress={(event) =>
                                                handleKeyPress(event, message)
                                              }
                                              onChange={handleContentChange}
                                            />
                                          </Grid>
                                        ) : (
                                          <div>
                                            <Grid item>
                                              {message?.message &&
                                                decrypt(
                                                  message?.message?.content
                                                )}
                                              {message?.type === "IMAGE" && (
                                                <img
                                                  src={
                                                    message?.message?.content
                                                  }
                                                  alt="attachment"
                                                  className="message-image"
                                                  style={{
                                                    width: "100px",
                                                    height: "80px",
                                                    borderRadius: "6px",
                                                  }}
                                                  //  onClick={() => setImagePreview(message?.message?.content)}

                                                  onClick={() =>
                                                    handleToggleviewgroupchat(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                />
                                              )}

                                              {message?.type == "DOCUMENT" && (
                                                <Grid
                                                  container
                                                  onClick={() =>
                                                    handleToggleviewgroupchat(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                  sx={{
                                                    padding: "10px 2px",
                                                    backgroundColor: "#F6F6F6",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    xs={1.3}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Grid
                                                      item
                                                      sx={{
                                                        width: "40px",
                                                        height: "40px",
                                                        backgroundColor:
                                                          "white",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        borderRadius: "4px",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/File_icon.png`}
                                                        alt="file_icon"
                                                        style={{
                                                          width: "33px",
                                                          height: "33px",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={10}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography className="Doc_lable">
                                                      {message?.message?.name}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={0.7}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={`..${subPath}/assets/images/images/forward_chat.png`}
                                                      alt="forward_chat"
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              )}
                                              {message?.type === "AUDIO" && (
                                                <>
                                                  <Grid
                                                    container
                                                    onClick={() =>
                                                      handleAudioview(
                                                        message?.message?.id
                                                      )
                                                    }
                                                    sx={{
                                                      padding: "10px 2px",
                                                      backgroundColor:
                                                        "#F6F6F6",
                                                      borderRadius: "4px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <Grid
                                                      item
                                                      xs={1.3}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/Music_icon.png`}
                                                        alt="Music_icon"
                                                        style={{
                                                          width: "33px",
                                                          height: "33px",
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={10}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <Typography className="audio_lable">
                                                        {message?.message?.name}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={0.7}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/forward_chat.png`}
                                                        alt="forward_chat"
                                                        style={{
                                                          width: "30px",
                                                          height: "30px",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  {audioOpen &&
                                                    audioview &&
                                                    audioview ===
                                                      message?.message?.id && (
                                                      <Grid
                                                        item
                                                        className="audio_view"
                                                        sx={{
                                                          mt: 1,
                                                        }}
                                                      >
                                                        <Grid container>
                                                          <Grid item xs={12}>
                                                            <AudioPlayer
                                                              ref={playerRef}
                                                              showFilledProgress={
                                                                true
                                                              }
                                                              src={
                                                                message?.message
                                                                  ?.content
                                                              }
                                                              customIcons={{
                                                                forward: (
                                                                  <CustomForwardButton />
                                                                ),
                                                                rewind: (
                                                                  <CustomRewindButton />
                                                                ),
                                                                play: (
                                                                  <CustomPlayButton />
                                                                ),
                                                                pause: (
                                                                  <CustomPauseButton />
                                                                ),
                                                              }}
                                                              onPlay={(e) =>
                                                                console.log(
                                                                  "onPlay"
                                                                )
                                                              }
                                                              onListen={
                                                                handleListen
                                                              }
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          xs={12}
                                                          sx={{
                                                            padding:
                                                              "10px 10px",
                                                          }}
                                                          item
                                                        >
                                                          <input
                                                            type="range"
                                                            className="progress_bar"
                                                            min={0}
                                                            max={duration}
                                                            value={currentTime}
                                                            onChange={(e) =>
                                                              handleSeek(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          sx={{
                                                            padding: "0px 10px",
                                                          }}
                                                        >
                                                          <Grid item xs={6}>
                                                            {formatTime(
                                                              currentTime
                                                            )}
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            sx={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "end",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            {formatTime(
                                                              duration
                                                            )}
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    )}
                                                </>
                                              )}

                                              {message?.type === "VIDEO" && (
                                                <div
                                                  onClick={() =>
                                                    handleToggleviewgroupchat(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                >
                                                  <div className="video">
                                                    <div
                                                      className="video_preview"
                                                      style={{
                                                        backgroundImage:
                                                          "url(https://raw.githubusercontent.com/Ahmetaksungur/twitter-video-player-clone/master/92456705_506989316849451_7379405183454806542_n.jpg)",
                                                      }}
                                                    >
                                                      <button
                                                        className="videostart_btn"
                                                        onClick={play}
                                                      >
                                                        <img
                                                          src={`..${subPath}/assets/images/images/play_icon.png`}
                                                          alt="play"
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <Grid item mt={1} xs={10}>
                                                    <Typography
                                                      title={
                                                        message?.message?.name
                                                      }
                                                      className="videoChat_lable"
                                                    >
                                                      {message?.message?.name}
                                                    </Typography>
                                                  </Grid>
                                                </div>
                                              )}
                                            </Grid>
                                            {message?.repliesCount > 0 && (
                                              <Grid
                                                container
                                                sx={{ justifyContent: "end" }}
                                              >
                                                <Grid item>
                                                  <img
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                    src={`..${subPath}/assets/images/images/circle_theard_icon.png`}
                                                    alt="theard"
                                                  />
                                                </Grid>

                                                <Grid item>
                                                  <Typography className="reply_count_text">
                                                    <span className="theard_dot"></span>
                                                    {message?.repliesCount}{" "}
                                                    Replies
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            )}
                                          </div>
                                        )
                                      }
                                      title={
                                        <Grid
                                          container
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={
                                              message?.forwardMessage === true
                                                ? 8
                                                : 4
                                            }
                                            md={
                                              message?.forwardMessage === true
                                                ? 4
                                                : 8
                                            }
                                            lg={
                                              message?.forwardMessage === true
                                                ? 5
                                                : 8
                                            }
                                            className="sender_lable"
                                          >
                                            {message.senderName
                                              .charAt(0)
                                              .toUpperCase() +
                                              message.senderName.slice(1)}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={
                                              message?.forwardMessage === true
                                                ? 4
                                                : 8
                                            }
                                            md={
                                              message?.forwardMessage === true
                                                ? 8
                                                : 4
                                            }
                                            lg={
                                              message?.forwardMessage === true
                                                ? 7
                                                : 4
                                            }
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            {message?.forwardMessage ===
                                            true ? (
                                              <Grid
                                                item
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Grid item mr={1.5}>
                                                  <img
                                                    src={`..${subPath}/assets/images/images/Group_forward.png`}
                                                    style={{
                                                      width: "14px",
                                                      height: "10px",
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item mr={1.5}>
                                                  <Typography
                                                    sx={{
                                                      fontFamily: "Montserrat",
                                                      fontSize: "11px",
                                                      fontWeight: "700",
                                                      color: "#848484",
                                                    }}
                                                  >
                                                    Forwarded
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  mr={1.5}
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={`..${subPath}/assets/images/images/Ellipse_Round.png`}
                                                    style={{
                                                      width: "6px",
                                                      height: "6px",
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                            <Grid
                                              item
                                              sx={{
                                                display: "flex",
                                                color: "#414B54",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                fontFamily: "Montserrat",
                                              }}
                                            >
                                              {moment(
                                                message.message.sendAt
                                              ).format("hh:mm A")}
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        // message?.senderName?.charAt(0)?.toUpperCase() +
                                        // message?.senderName?.slice(1)
                                      }
                                      focus={true}
                                      titleColor={"#000000"}
                                      replyButton={false}
                                      removeButton={false}
                                      date={new Date(message?.message?.sendAt)}
                                      forwarded={message?.forwardMessage}
                                      status={"sent"}
                                      notch={true}
                                      retracted={false}
                                    />
                                  </Typography>
                                  {hoveredMessageReply === index && (
                                    <CircleMessageTooltip
                                      groupId={groupDetails.groupId}
                                      msgId={message?.message?.id}
                                      forwardMessage={() =>
                                        handleForwardMessageShow(message)
                                      }
                                      DeleteMessage={() =>
                                        handleDeleteMessage(message)
                                      }
                                      PinClick={() => handlePinShow(message)}
                                      ReplyMessage={() =>
                                        handleReplyClick(
                                          index,
                                          message?.message?.id
                                        )
                                      }
                                      ToggleMediaView={() =>
                                        handleToggleMediaView(
                                          message?.senderProfilePicture,
                                          message?.message?.id,
                                          groupDetails.groupId,
                                          message?.senderName,
                                          decrypt(message?.message?.content),
                                          message?.senderId,
                                          moment(message.message.sendAt).format(
                                            "hh:mm A"
                                          )
                                        )
                                      }
                                      MouseEnter={() =>
                                        setHoveredMessageReply(index)
                                      }
                                      EditClick={() =>
                                        handleEditClick(
                                          index.toString(),

                                          message?.message &&
                                            decrypt(message?.message?.content)
                                        )
                                      }
                                    />
                                  )}
                                </Box>
                              </Grid>
                              <CircleTheard
                                senderAt={senderAt}
                                sendPicture={sendPicture}
                                sendMsgId={sendMsgId}
                                sendId={sendId}
                                dataName={dataName}
                                dataContent={dataContent}
                                replyData={replyData}
                                groupDetails={groupDetails}
                                userData={userData}
                                message={message}
                              />
                            </Box>

                            <div>
                              {isReplying && replyingIndex === index && (
                                <ChatReply
                                    chatDetails={groupDetails}
                                  message={message}
                                  index={index}
                                  isProfileShow={isProfileShow}
                                  isForwardMessage={isForwardMessageShow} 
                                  userData={undefined}
                                   isReplying={false}  
                                   />
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Grid item sx={{ position: "sticky", right: 0, top: "100px" }}>
              {isProfileShow && (
                <CircleProfileView
                  groupDetails={groupDetails}
                  onBack={handleProfileClick}
                />
              )}
              {isForwardMessageShow && (
                <CircleForwardMessage
                  isPinShow={isPinShow}
                  forwardMsg={forwardMsg}
                  chatData={forwardMeassgeDetails}
                  forwardMessage={handleCloseForwardMessage}
                />
              )}
            </Grid>

            {!isTheardOpen && (
              <Grid
                container
                className={
                  isProfileShow || isForwardMessageShow
                    ? "profile_show_container"
                    : "profile_hide_container"
                }
                sx={{
                  height: "50px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #00000026",
                  borderLeft: "0px solid",
                  zIndex: 1,
                  position: "fixed",
                  bottom: 0,
                  width: () => ({
                    xs:
                      isProfileShow || isForwardMessageShow ? "47.55%" : "70%",
                    sm:
                      isProfileShow || isForwardMessageShow ? "49.55%" : "72%",

                    md: isProfileShow || isForwardMessageShow ? "50.%" : "73%",
                    lg: isProfileShow || isForwardMessageShow ? "51.3%" : "74%",
                    xl: isProfileShow || isForwardMessageShow ? "52%" : "75%",
                  }),
                }}
              >
                <Grid
                  item
                  xs={1}
                  md={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                  lg={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <img
                    src={`..${subPath}/assets/images/images/element-plus.png`}
                    alt="plus"
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    onClick={handleImageClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={isProfileShow || isForwardMessageShow ? 10.7 : 10.7}
                  lg={isProfileShow || isForwardMessageShow ? 10.7 : 10.8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <InputBase
                    placeholder="Type a message"
                    className="ml-15 input_search"
                    sx={{
                      background: "#fffff",
                      color: "#708090",
                      boxSizing: "border-box",
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                    id="searchText"
                    autoComplete="off"
                    value={messageInput}
                    onChange={handleChageInput}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                {showMentionList && messageInput.length>0 && (
        <ul
          style={{
            position: "absolute",
            top: "-259px", // Adjust based on input field position
            left: "10px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            height: "247px",
            overflow: "scroll",
            borderRadius: "5px",
            listStyleType: "none",
            padding: "5px",
            zIndex: 1000,
          }}
        >
          {filteredMembers.map((member, index) => (
            <li
              key={index}
              onClick={() => handleMentionClick(member)}
              style={{ cursor: "pointer", padding: "5px 10px" }}
            >
              {member}
            </li>
          ))}
        </ul>
      )}
                <Grid
                  item
                  xs={1}
                  md={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                  lg={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={`..${subPath}/assets/images/images/send_chat.png`}
                    alt="plus"
                    style={{ width: "24px", height: "24px" }}
                    onClick={sendMessage}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default CircleView;
