import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  code?: any | null;
  profilePicture: any | null;
  Email: string | null;
  Name: string | null;
  exp: number | null;
  groupAccess: boolean | null;
  iat: number | null;
  id: string | null;
  role: string | null;
  sub: string | null;
  vaultAccess: boolean | null;
  companyId: string | null;
}

// const baseGatewayUrl = "https://trovekafkachat.ckdigital.in/api/";
// const http = `${baseGatewayUrl}`;
// const chat_http = `${baseGatewayUrl}`;

const baseGatewayUrl = "https://trove-qa-gateway.citpl.info/";
const http = `${baseGatewayUrl}km-module/`;
const chat_http = `${baseGatewayUrl}chat-module`;

// const http = "http://localhost:8080/api/";
const CHAT_SOCKET_URL_LOCAL = "ws://192.168.111.161:8080/ws/";
const CHAT_SOCKET_URL_STAGE = "wss://trovechatprd.ckdigital.in/api/ws/";
const imagehttp = " ";
const subPath = "";

export {
  http,
  imagehttp,
  subPath,
  chat_http,
  CHAT_SOCKET_URL_LOCAL,
  CHAT_SOCKET_URL_STAGE,
};
export const getToken = () => {
  const tokenString = localStorage.getItem("KM_Admin");
  return tokenString ? JSON.parse(tokenString) : { Token: "" };
};

export const decodeToken = (): JwtPayload => {
  const tokenInfo = getToken();
  const token = tokenInfo ? tokenInfo.Token : null;
  if (!token) {
    // Handle the case where the token is missing or invalid
    return {
      profilePicture: null,
      Email: null,
      Name: null,
      groupAccess: null,
      exp: null,
      iat: null,
      id: null,
      role: null,
      sub: null,
      vaultAccess: null,
      companyId: null,
      code: null,
    };
  }
  const decoded = jwtDecode(token);
  const extendedDecoded: JwtPayload = {
    profilePicture: (decoded as any).profilePicture ?? null,
    Email: (decoded as any).Email || null,
    Name: (decoded as any).Name || null,
    groupAccess: (decoded as any).groupAccess || null,
    exp: (decoded as any).exp || null,
    iat: (decoded as any).iat || null,
    id: (decoded as any).id || null,
    role: (decoded as any).role || null,
    sub: (decoded as any).sub || null,
    vaultAccess: (decoded as any).vaultAccess || null,
    companyId: (decoded as any).companyId || null,
    code: (decoded as any).code || null,
  };

  return extendedDecoded;
};

export const ENC_DEC_KEY = "cavin*info*tech@";
