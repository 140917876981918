import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../Auth/Login.css";
import { http, subPath } from "../../Constants/Global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios, { AxiosError } from "axios";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import { jwtDecode } from "jwt-decode";
import CopyrightIcon from "@mui/icons-material/Copyright";
import UseAxios from "../../Constants/UseAxios";

type loginInfo = {
  email: any;
  password: any;
};

const Login: React.FC = () => {
  const location = useLocation();
  const api = UseAxios();
  const navigate = useNavigate();
  const navigates = () => {
    navigate(`/HelpandSupport`);
  };
  const [isFilled, setIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<loginInfo>({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const adminLoginString = localStorage.getItem("KM_Tool");
  const adminLogin = adminLoginString ? JSON.parse(adminLoginString) : null;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (formData.email && formData.password) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
      // Remove the red border when the user starts typing
      const errorField = document.getElementById(name + "Error");
      if (errorField) {
        errorField.innerHTML = "";
      }
    }
  };

  useEffect(() => {
    if (adminLogin === true) {
      // navigate("/landingAssetView");
      navigate("/landingAssetView");
    }
  }, [adminLogin, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("labelText");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields?.[i]?.classList.remove("textRed");
    }

    // Validate Before Submitting
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Incorrect Mail ID Format")
        .test("required", "Please fill out the fields", function (value) {
          return !!value && value.trim() !== ""; // Convert to boolean
        }),
      password: Yup.string().test(
        "required",
        "Please fill out the fields",
        function (value) {
          return !!value && value.trim() !== ""; // Convert to boolean
        }
      ),
    });

    const requestBody = {
      email: formData.email,
      password: formData.password,
    };

    schema
      .validate(requestBody, { abortEarly: false })
      .then(async () => {
        try {
          const res = await api.post(`user/signin`, requestBody);
          const responseData = res.data;
          if (!responseData.status) {
            setIsLoading(false);
            for (let i = 0; i < inputFields.length; i++) {
              inputFields?.[i]?.classList.add("textRed");
            }
          } else {
            setIsLoading(false);
            localStorage.setItem("KM_Admin", JSON.stringify(responseData.data));
            localStorage.setItem("KM_Tool", "true"); // Parse the JSON response
            localStorage.setItem("logout", "normal");
            setTimeout(() => {
              // window.location.href = "/landingAssetView";
              window.location.href = "/landingAssetView";
            }, 1000);
          }
        } catch (error) {
          setIsLoading(false);
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              for (const key in errorArray) {
                var keyVal = errorArray[key].trim().split(":");
                const errorField = document.getElementById(keyVal[0] + "Error");
                const inputField = document.getElementById(
                  keyVal[0].trim() + "Text"
                ) as HTMLInputElement;
                inputField?.classList.add("textRed");
                if (errorField) {
                  errorField.innerHTML =
                    keyVal[1].charAt(0).toUpperCase() +
                    keyVal[1].slice(1) +
                    ".";
                }
              }
            } else {
              toast.error(error.response?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.error((error as AxiosError).message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        setIsLoading(false);
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "Error"
          );
          const inputField = document.getElementById(
            validationErrors.inner[i].path + "Text"
          );
          inputField?.classList.add("textRed");
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };

  useEffect(() => {
    // Parse URL parameters using query-string
    const urlParams = queryString.parse(window.location.search);

    const tokenstatus = urlParams.Token;
    let tokencode;
    if (typeof tokenstatus === "string") {
      const decoded = jwtDecode(tokenstatus);
      if ("code" in decoded) {
        tokencode = decoded.code;
      }
    }
    // const statusCode = new URLSearchParams(location.search).get("code");
    if (tokencode == 200) {
      localStorage.setItem("KM_Admin", JSON.stringify(urlParams));
      localStorage.setItem("KM_Tool", "true");
      localStorage.setItem("logout", "outlook");
      const adminLogin = adminLoginString ? JSON.parse(adminLoginString) : null;
      // navigate("/landingAssetView");
      navigate("/landingAssetView");
    } else if (tokencode) {
      toast.error("User Not Found", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const rUrl = new URLSearchParams(location.search).get("redirectUrl");

      // After 5 seconds, redirect to the login page
      // setTimeout(() => {
      //   window.location.href = rUrl ? rUrl : "/";
      // }, 5000);

      navigate("/");
    }
  }, [navigate]);

  const handleCreateKnowledgeClick = (): void => {
    const url = http + `oauth2/authorization/azure-qa`;
    window.location.href = url;
  };

  return (
    <div>
      {!isMobile ? (
        <div className="login_bg ">
          <Grid>
            <Box
              component="img"
              className="logo"
              width="100px"
              sx={{ mt: 3, ml: 4 }}
              src={`..${subPath}/assets/images/logo.svg`}
            />
          </Grid>
          <Grid container style={{ flexDirection: "column", marginTop: "25px" }}>
            <div className="content-login">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid>
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "372px",
                    }}
                    className="left_img"
                    src={`..${subPath}/assets/images/Login Vector.png`}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="right_text">
                <Grid>
                  <Typography className="fs-30 fw-600">Hello !</Typography>
                  <Typography className="text_light">
                    Unlock the Vault of Knowledge – Sign In Now!
                  </Typography>
                </Grid>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 5 }}
                  className="form_width"
                >
                  <Box>
                    <Typography
                      id="emailText"
                      className={`labelText fs-14 ${formData.email ? "text-red" : ""
                        }`}
                    >
                      Email
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      type="text"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="login_input field email"
                      autoComplete="off"
                    />
                    <span
                      id="emailError"
                      style={{ color: "red" }}
                      className="error"
                    ></span>
                  </Box>

                  <Box className="mt-30 ">
                    <Typography
                      id="passwordText"
                      className={`labelText fs-14 ${formData.password ? "text-red" : ""
                        }`}
                    >
                      Password
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="login_input field password edge_browser"
                      autoComplete="new-password"
                      InputProps={{
                        inputProps: {
                          maxLength: 22,
                        },
                        style: { height: "35px", color: "#708090" },
                        endAdornment: (
                          <IconButton
                            onClick={togglePasswordVisibility}
                            aria-label="Toggle password visibility"
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    <span
                      id="passwordError"
                      style={{ color: "red" }}
                      className="error"
                    ></span>
                  </Box>

                  <Grid container className="eyeicon">
                    <Grid item xs></Grid>
                    <Grid item>
                      {/* <Link
                      href="#"
                      variant="body2"
                      className="mt-30"
                      sx={{
                        color: "#f5f5f5 ",
                        textDecoration: "underline",
                        marginTop: "10px",
                      }}
                    >
                      {"Forgot password"}
                    </Link> */}
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    // variant="contained"
                    className={isFilled ? "sign_btn" : ""}
                    sx={{
                      textTransform: "none",
                      color: "#f5f5f5",
                      background: isFilled ? "#4CCE1F" : "",
                      borderRadius: "8px",
                      border: isFilled ? "1px solid #4CCE1F" : "1px solid #F5F5F5",
                      boxShadow: "0px 4px 12px 0px rgba(53, 53, 53, 0.16)",
                      marginTop: "32px",
                      mt: 5,
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Sign In"}
                  </Button>
                  <Typography
                    variant="h5"
                    className=" text-white fs-14"
                    sx={{
                      mt: 2,
                      textAlign: "center",
                    }}
                  >
                    or
                  </Typography>

                  <Button
                    // type="submit"
                    fullWidth
                    variant="contained"
                    className="btn-white"
                    onClick={handleCreateKnowledgeClick}
                    sx={{
                      textTransform: "none",
                      color: "var(--Blue, #119BFF)",
                      background: "#fff",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      borderRadius: "7px",
                      mt: 2,
                      "&:hover": {
                        background: "#fff", // Set the background to the regular state value
                        color: "var(--Blue, #119BFF)", // Set the text color to the regular state value
                      },
                    }}
                  >
                    <Link
                      component="img"
                      src={`..${subPath}/assets/images/Microsoft logo.svg`}
                      sx={{ marginRight: "9px" }}
                    ></Link>
                    Sign In With Microsoft
                  </Button>
                </Box>
              </Grid>
            </div>
            <Paper
              className="footer_m"
              sx={{
                bottom: 0,
                width: "100%",
                background: "transparent",
                border: "none",
              }}
              square
              variant="outlined"
            >
              <Grid container style={{ marginTop: "10%" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <Grid className="fooimg">
                    <Box
                      component="img"
                      src={`..${subPath}/assets/images/cavin-info.png`}
                      width={"40px"}
                    />
                  </Grid>
                  <Grid>
                    <Typography className="copyright_st">
                      All Rightss{" "}
                      <CopyrightIcon style={{ width: "18px", margin: "0px 5px" }} />{" "}
                      Cavin Infotech
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="About_padding"
                >
                  <Typography
                    sx={{
                      float: "right",
                      color: "#fff",
                      fontSize: "14px",
                      margin: "revert",
                      marginRight: "15px",
                    }}
                  >
                    About &nbsp;|&nbsp; FAQ&nbsp; |&nbsp;{" "}
                    <span className="cur-pointer" onClick={() => navigates()}>
                      Help&nbsp; & &nbsp;Support
                    </span>{" "}
                    &nbsp;| &nbsp;Contact Us
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      ) : (
        <h1
          style={{
            color: "#708090",
            width: "100%",
            height: "100%",
            paddingTop: "20px",
            marginLeft: "20px",
            alignItems: "center",
          }}
        >
          This content is not supported on mobile devices.
        </h1>
      )}
    </div>
  );
};
export default Login;
